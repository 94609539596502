
import * as weservRuntime$LW0MYbOCWW from '/vercel/path0/node_modules/.pnpm/@nuxt+image@1.7.0_ioredis@5.4.1_rollup@4.20.0/node_modules/@nuxt/image/dist/runtime/providers/weserv'
import * as vercelRuntime$bNpQZcvvbi from '/vercel/path0/node_modules/.pnpm/@nuxt+image@1.7.0_ioredis@5.4.1_rollup@4.20.0/node_modules/@nuxt/image/dist/runtime/providers/vercel'

export const imageOptions = {
  "screens": {
    "xs": 576,
    "sm": 768,
    "md": 996,
    "lg": 1200,
    "xl": 1367,
    "xxl": 1600,
    "2xl": 1536,
    "default": 320,
    "xxs": 480,
    "4k": 1921
  },
  "presets": {
    "prod_small_thumbnail": {
      "modifiers": {
        "format": "webp",
        "width": 90,
        "height": 90,
        "fit": "fill"
      }
    },
    "prod_thumbnail": {
      "modifiers": {
        "format": "webp",
        "width": 300,
        "height": 300,
        "fit": "fill"
      }
    },
    "prod_listing": {
      "modifiers": {
        "format": "webp",
        "width": 500,
        "height": 500,
        "fit": "fill"
      }
    },
    "prod_picture": {
      "modifiers": {
        "format": "webp",
        "width": 800,
        "height": 800,
        "fit": "fill"
      }
    }
  },
  "provider": "vercel",
  "domains": [
    "backend.bazari.it",
    "bazari-products-final-v2.fra1.cdn.digitaloceanspaces.com"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp",
    "avif",
    "png",
    "webp"
  ],
  "quality": 100
}

imageOptions.providers = {
  ['weserv']: { provider: weservRuntime$LW0MYbOCWW, defaults: {"baseURL":"https://bazari.it"} },
  ['vercel']: { provider: vercelRuntime$bNpQZcvvbi, defaults: {} }
}
        