import { Product } from "@medusajs/medusa";
import type { Wishlist, wishlistPostReqParams } from "~/types";
export const useWishlistStore = defineStore("wishlistStore", {
  state: () => ({
    wishlist: [] as Wishlist[],
  }),
  getters: {
    wishlist_length: (state) => state.wishlist.length,
    allProducts: (state) => {
      const _prods = state.wishlist.flatMap((x) => x.products)
      return _prods
      // const prods = [] as Product[];
      // state.wishlist.map((_wish) => {
      //   if (!_wish.products?.length) {
      //     return;
      //   }
      //   _wish.products.map((_prod) => {
      //     prods.push(_prod as any);
      //   });
      // });
      // return prods;
    },
  },
  actions: {
    async getAll() {
      const customerStore = useCustomerStore();
      const d = await useCybandyClient().customMethods.wishlist.list({
        customer_id: customerStore.customer.id,
        expand: "products"
      });
      if (d.count > 0) {
        this.wishlist = d.wishlist;
      }
    },

    async create(payload: Omit<wishlistPostReqParams, "customer_id">) {
      const _payload = {
        ...payload,
        customer_id: useCustomerStore().customer.id,
      };
      const _wish =
        await useCybandyClient().customMethods.wishlist.create(_payload);
      if (this.wishlist) {
        this.wishlist.push(_wish);
        return _wish;
      }
    },
  },
});
