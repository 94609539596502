import { default as indexu65eKgC0sKMeta } from "/vercel/path0/pages/account/favourite/index.vue?macro=true";
import { default as indexqOnLrLMUjLMeta } from "/vercel/path0/pages/account/index.vue?macro=true";
import { default as indexrVzRtN8So8Meta } from "/vercel/path0/pages/account/orders/index.vue?macro=true";
import { default as indexy8Ak6I2293Meta } from "/vercel/path0/pages/account/security/index.vue?macro=true";
import { default as _91id_93gvT9C0mmrlMeta } from "/vercel/path0/pages/account/wishlist/[id].vue?macro=true";
import { default as index2Ard2qBP3hMeta } from "/vercel/path0/pages/account/wishlist/index.vue?macro=true";
import { default as confirm_45password_45resetYglSQZUBA8Meta } from "/vercel/path0/pages/auth/confirm-password-reset.vue?macro=true";
import { default as indexkppHktB81xMeta } from "/vercel/path0/pages/auth/index.vue?macro=true";
import { default as indexmfxAx2eWMBMeta } from "/vercel/path0/pages/blog/index.vue?macro=true";
import { default as indexraiq4bUafSMeta } from "/vercel/path0/pages/checkout/index.vue?macro=true";
import { default as _91cart_id_935JhLyJ23cPMeta } from "/vercel/path0/pages/checkout/success/[cart_id].vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as inviteRMrQn5eJPZMeta } from "/vercel/path0/pages/invite.vue?macro=true";
import { default as indexDqV3Lxa5AvMeta } from "/vercel/path0/pages/search/index.vue?macro=true";
import { default as wishlist_46client7aGS3Lac7UMeta } from "/vercel/path0/pages/share/wishlist.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.2.0_eslint@9.9.0_jiti@2.4.0__ioredis@5.4.1_o_rlqpwk4vbruvx2kz4dtpaqnwxe/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as indexN8QAORuvpUMeta } from "/vercel/path0/pages/shop/index.vue?macro=true";
import { default as _91handle_93KPSGOkS8KrMeta } from "/vercel/path0/pages/shop/jewels/[handle].vue?macro=true";
import { default as _91handle_93rbxc9SMLQ8Meta } from "/vercel/path0/pages/shop/jewels/categories/[handle].vue?macro=true";
import { default as indexAYwT4tKgvGMeta } from "/vercel/path0/pages/shop/jewels/index.vue?macro=true";
import { default as index7tzTPwTAnfMeta } from "/vercel/path0/pages/shopping-bag/index.vue?macro=true";
import { default as contactkpGWPbXDCCMeta } from "/vercel/path0/pages/support/contact.vue?macro=true";
import { default as cookie_45policyGV74i5vXF2Meta } from "/vercel/path0/pages/support/cookie-policy.vue?macro=true";
import { default as faqMadxkL8VknMeta } from "/vercel/path0/pages/support/faq.vue?macro=true";
import { default as indexoa8UIM335lMeta } from "/vercel/path0/pages/support/index.vue?macro=true";
import { default as privacy_45policyx6ZXBrgu7DMeta } from "/vercel/path0/pages/support/privacy-policy.vue?macro=true";
import { default as tncM3PqMgLpi3Meta } from "/vercel/path0/pages/support/tnc.vue?macro=true";
export default [
  {
    name: "account-favourite",
    path: "/account/favourite",
    meta: indexu65eKgC0sKMeta || {},
    component: () => import("/vercel/path0/pages/account/favourite/index.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/account",
    meta: indexqOnLrLMUjLMeta || {},
    component: () => import("/vercel/path0/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders",
    path: "/account/orders",
    meta: indexrVzRtN8So8Meta || {},
    component: () => import("/vercel/path0/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-security",
    path: "/account/security",
    meta: indexy8Ak6I2293Meta || {},
    component: () => import("/vercel/path0/pages/account/security/index.vue").then(m => m.default || m)
  },
  {
    name: "account-wishlist-id",
    path: "/account/wishlist/:id()",
    component: () => import("/vercel/path0/pages/account/wishlist/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-wishlist",
    path: "/account/wishlist",
    meta: index2Ard2qBP3hMeta || {},
    component: () => import("/vercel/path0/pages/account/wishlist/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-confirm-password-reset",
    path: "/auth/confirm-password-reset",
    component: () => import("/vercel/path0/pages/auth/confirm-password-reset.vue").then(m => m.default || m)
  },
  {
    name: "auth",
    path: "/auth",
    component: () => import("/vercel/path0/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/vercel/path0/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: indexraiq4bUafSMeta || {},
    component: () => import("/vercel/path0/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-success-cart_id",
    path: "/checkout/success/:cart_id()",
    meta: _91cart_id_935JhLyJ23cPMeta || {},
    component: () => import("/vercel/path0/pages/checkout/success/[cart_id].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invite",
    path: "/invite",
    meta: inviteRMrQn5eJPZMeta || {},
    component: () => import("/vercel/path0/pages/invite.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/vercel/path0/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "share-wishlist",
    path: "/share/wishlist",
    component: () => createClientPage(() => import("/vercel/path0/pages/share/wishlist.client.vue").then(m => m.default || m))
  },
  {
    name: "shop",
    path: "/shop",
    component: () => import("/vercel/path0/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-jewels-handle",
    path: "/shop/jewels/:handle()",
    component: () => import("/vercel/path0/pages/shop/jewels/[handle].vue").then(m => m.default || m)
  },
  {
    name: "shop-jewels-categories-handle",
    path: "/shop/jewels/categories/:handle()",
    component: () => import("/vercel/path0/pages/shop/jewels/categories/[handle].vue").then(m => m.default || m)
  },
  {
    name: "shop-jewels",
    path: "/shop/jewels",
    component: () => import("/vercel/path0/pages/shop/jewels/index.vue").then(m => m.default || m)
  },
  {
    name: "shopping-bag",
    path: "/shopping-bag",
    meta: index7tzTPwTAnfMeta || {},
    component: () => import("/vercel/path0/pages/shopping-bag/index.vue").then(m => m.default || m)
  },
  {
    name: "support-contact",
    path: "/support/contact",
    meta: contactkpGWPbXDCCMeta || {},
    component: () => import("/vercel/path0/pages/support/contact.vue").then(m => m.default || m)
  },
  {
    name: "support-cookie-policy",
    path: "/support/cookie-policy",
    component: () => import("/vercel/path0/pages/support/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: "support-faq",
    path: "/support/faq",
    meta: faqMadxkL8VknMeta || {},
    component: () => import("/vercel/path0/pages/support/faq.vue").then(m => m.default || m)
  },
  {
    name: "support",
    path: "/support",
    component: () => import("/vercel/path0/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: "support-privacy-policy",
    path: "/support/privacy-policy",
    component: () => import("/vercel/path0/pages/support/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "support-tnc",
    path: "/support/tnc",
    component: () => import("/vercel/path0/pages/support/tnc.vue").then(m => m.default || m)
  }
]