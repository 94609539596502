import revive_payload_client_AXClklOyhJ from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.2.0_eslint@9.9.0_jiti@2.4.0__ioredis@5.4.1_o_rlqpwk4vbruvx2kz4dtpaqnwxe/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_IYXhr4yQ9I from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.2.0_eslint@9.9.0_jiti@2.4.0__ioredis@5.4.1_o_rlqpwk4vbruvx2kz4dtpaqnwxe/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jb2Xde6n1v from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.2.0_eslint@9.9.0_jiti@2.4.0__ioredis@5.4.1_o_rlqpwk4vbruvx2kz4dtpaqnwxe/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_v7RGVWFlGH from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.15_rollup@4.20.0_vite@5.4.0_@types+node@22.2.0_terser@5.31.5__vue@3.4.37_typescript@5.5.4_/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_WyhiKauxSk from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.2.0_eslint@9.9.0_jiti@2.4.0__ioredis@5.4.1_o_rlqpwk4vbruvx2kz4dtpaqnwxe/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Tp69g508lN from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.2.0_eslint@9.9.0_jiti@2.4.0__ioredis@5.4.1_o_rlqpwk4vbruvx2kz4dtpaqnwxe/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_uWxibskX9I from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.2.0_eslint@9.9.0_jiti@2.4.0__ioredis@5.4.1_o_rlqpwk4vbruvx2kz4dtpaqnwxe/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_jcLoy4gpGt from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.2.0_eslint@9.9.0_jiti@2.4.0__ioredis@5.4.1_o_rlqpwk4vbruvx2kz4dtpaqnwxe/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Vuczcfgx8W from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.3_rollup@4.20.0_typescript@5.5.4_vue@3.4.37_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_OCFuQM9Vhq from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.2.0_eslint@9.9.0_jiti@2.4.0__ioredis@5.4.1_o_rlqpwk4vbruvx2kz4dtpaqnwxe/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_XTLNZZsQR1 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+web-vitals@0.2.7_rollup@4.20.0/node_modules/@nuxtjs/web-vitals/dist/runtime/plugin.client.mjs";
import plugin_client_so0CLZEW6p from "/vercel/path0/node_modules/.pnpm/nuxt-vercel-analytics@1.0.2_rollup@4.20.0/node_modules/nuxt-vercel-analytics/dist/runtime/plugin.client.mjs";
import plugin_LS9SZYpPa0 from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_rollup@4.20.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import titles_URnjtbmJac from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.18_h3@1.12.0_rollup@4.20.0_vite@5.4.0_@types+node@22.2.0_terser@5.31.5___3xip57dvgodfxukbxsbzkdvjpm/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_NkG1pOhMHe from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.0_rollup@4.20.0_vite@5.4.0_@types+node@22.2.0_terser@5.31.5__vue@3.4.37_typescript@5.5.4_/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_1bAMd8OW5y from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.0_rollup@4.20.0_vite@5.4.0_@types+node@22.2.0_terser@5.31.5__vue@3.4.37_typescript@5.5.4_/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_sAoUrygwyD from "/vercel/path0/node_modules/.pnpm/@nuxt+image@1.7.0_ioredis@5.4.1_rollup@4.20.0/node_modules/@nuxt/image/dist/runtime/plugin.mjs";
import slideovers_arGxMU0ig8 from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.18.4_axios@0.24.0_focus-trap@7.5.4_rollup@4.20.0_vite@5.4.0_@types+node@22.2.0_ter_auxxiaaezcnh2i6yaax6keoasy/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_NHBXrMvfO5 from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.18.4_axios@0.24.0_focus-trap@7.5.4_rollup@4.20.0_vite@5.4.0_@types+node@22.2.0_ter_auxxiaaezcnh2i6yaax6keoasy/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_3Lqn6WU9jz from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.18.4_axios@0.24.0_focus-trap@7.5.4_rollup@4.20.0_vite@5.4.0_@types+node@22.2.0_ter_auxxiaaezcnh2i6yaax6keoasy/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_6cDWIIzeS6 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2_rollup@4.20.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_0aTgJHeZXu from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.4.5_rollup@4.20.0_vite@5.4.0_@types+node@22.2.0_terser@5.31.5__vue@3.4.37_typescript@5.5.4_/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_UOPHMmplPJ from "/vercel/path0/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.3_rollup@4.20.0_typescript@5.5.4_vue@_dqrvnnefmzwdmus2azpljudbu4/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import _02_product_OktlwvhuiB from "/vercel/path0/plugins/02.product.ts";
import _03_general_46dx8Fy8G7 from "/vercel/path0/plugins/03.general.ts";
import aos_client_Gr50AN4Ul1 from "/vercel/path0/plugins/aos.client.js";
import bugsnag_tkMQfOjcVZ from "/vercel/path0/plugins/bugsnag.ts";
import company_j3fM3zO30U from "/vercel/path0/plugins/company.ts";
import theming_client_dZ2rpcH7wL from "/vercel/path0/plugins/theming.client.ts";
import utilities_Hys3Y2ToiA from "/vercel/path0/plugins/utilities.ts";
import defaults_ReDMBazvfu from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.18_h3@1.12.0_rollup@4.20.0_vite@5.4.0_@types+node@22.2.0_terser@5.31.5___3xip57dvgodfxukbxsbzkdvjpm/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  revive_payload_client_AXClklOyhJ,
  unhead_IYXhr4yQ9I,
  router_jb2Xde6n1v,
  _0_siteConfig_v7RGVWFlGH,
  payload_client_WyhiKauxSk,
  navigation_repaint_client_Tp69g508lN,
  check_outdated_build_client_uWxibskX9I,
  chunk_reload_client_jcLoy4gpGt,
  plugin_vue3_Vuczcfgx8W,
  components_plugin_KR1HBZs4kY,
  prefetch_client_OCFuQM9Vhq,
  plugin_client_XTLNZZsQR1,
  plugin_client_so0CLZEW6p,
  plugin_LS9SZYpPa0,
  titles_URnjtbmJac,
  siteConfig_NkG1pOhMHe,
  inferSeoMetaPlugin_1bAMd8OW5y,
  plugin_sAoUrygwyD,
  slideovers_arGxMU0ig8,
  modals_NHBXrMvfO5,
  colors_3Lqn6WU9jz,
  plugin_client_6cDWIIzeS6,
  plugin_0aTgJHeZXu,
  plugin_UOPHMmplPJ,
  _02_product_OktlwvhuiB,
  _03_general_46dx8Fy8G7,
  aos_client_Gr50AN4Ul1,
  bugsnag_tkMQfOjcVZ,
  company_j3fM3zO30U,
  theming_client_dZ2rpcH7wL,
  utilities_Hys3Y2ToiA,
  defaults_ReDMBazvfu
]